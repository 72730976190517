.main-layout {
  padding-top: min(15vh, 90px);
}

@media (max-width: 1026px) {
  .main-layout {
    padding-top: 7vh;
  }
}

@media (max-width: 766px) {
  .main-layout {
    padding-top: 15vh;
  }
}

@media (max-width: 380px) {
  .main-layout {
    padding-top: 20vh;
    padding-bottom: 0;
  }
}

/* For basicMenu  */
.no-scroll {
  overflow: hidden;
  position: fixed; /* Optional, for better handling on some mobile browsers */
  width: 100%; /* Optional, for better handling on some mobile browsers */
}

/* .leaflet-control-attribution {
  display: block !important;
} */
