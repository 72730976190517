.navigationButtons {
  /* width: 95%; */
  height: 20px;
  list-style: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 50px;
  padding-left: 0px;
  font-size: 1.1em;
  align-items: center;
}

.navigationButtons .previousButton,
.navigationButtons .nextButton {
    font-size: 1.5em;  /* Adjust this value as per your requirement */

    padding-bottom: 6px;
    
}

.navigationButtons a {
  padding: 5px 3px;
  margin: 4px;
  border-radius: 2px;
  /* border: 1px solid white; */
  color: white;
  cursor: pointer;
}

.navigationButtons a:hover {
  color: black;
  background-color: #F8D6FE;
}

.navigationActive a {
  color: black;
  background-color: #F8D6FE;
}

/* .navigationDisabled a {
  color: grey;
  background-color: grey;
} */


@media (max-width: 768px) { 
  .navigationButtons .previousButton:hover, 
  .navigationButtons .nextButton:hover {
    color: white;
    background-color: transparent;
  }
}