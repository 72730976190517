/* Mobile First Approach */

.links {
  display: flex;
  align-items: center;

  flex-shrink: 0;
  padding-top: 10px;
}

@media (max-width: 650px) {
  .links {
    display: block;
    margin: 0;
    padding: 2px 0px;
  }
}

@media (max-width: 881px) {
  .links {
    padding-top: 0;
  }
}

/* Base Styles: These styles apply for all devices initially */
.link,
.link-active {
  position: relative;

  text-decoration: none;
  font-size: 23px;
  font-family: inherit;
  color: inherit;
  padding-bottom: 2px;
}

/* Smaller mobile styles */
@media (max-width: 380px) {
  .link,
  .link-active {
    font-size: 20px;
    margin: 0;
    margin-right: 25px;
  }
}

/* Mobile styles */
@media (min-width: 381px) and (max-width: 650px) {
  .link,
  .link-active {
    font-size: 22px;
    margin: 0;
    padding: 2px 0px;
    margin-right: 25px;
  }
}

/* Tablet and small desktop styles */
@media (min-width: 651px) and (max-width: 768px) {
  .link,
  .link-active {
    margin: 0;
    width: fit-content;
    font-size: 22px;
    margin-right: 25px;
  }
}

/* Desktop styles */
@media (min-width: 769px) {
  .link:hover,
  .link-active {
    color: #f8d6fe;
    margin: 0 30px;
  }

  .link {
    margin: 0 30px;
  }

  .link:hover:before {
    width: 100%;
    left: 0%;
  }

  .logo:hover .blink {
    animation: logoAnimate 3.2s infinite;
  }
}

/* Other styles and animations */

.link:before,
.link-active:before {
  position: absolute;
  bottom: 0;
  left: 50%;
  content: "";
  background-color: #f8d6fe;
  width: 0%;
  height: 2px;
  transition: width 0.7s, left 0.7s;
}

.link-active:before {
  width: 100%;
  left: 0%;
}

@keyframes logoAnimate {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
