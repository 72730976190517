@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 400px;
  height: 50px;
  background-color: black;
  color: #fff;
  padding: 5px 10px;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  border-top: 2px solid white;
  border-right: 2px solid white;
  z-index: 20;
  animation: slideUp 0.3s ease-in forwards;
}

.volume-container {
  position: relative;
  display: flex;
  align-items: center;
  height: 70px; /* Adjust to match the height of the footer */
}

.slider-initial-hidden {
  display: none;
}

.slider-container {
  position: fixed;
  bottom: 55px; /* Aligns the bottom of the slider with the top of the volume button */
  left: 341px;
  right: 0;
  height: 110px;
  width: 40px; /* Width of the container */
  border: 2px solid white; /* Border for the container */
  background-color: black; /* Background color of the container */
  padding: 20px 10px 10px 10px; /* Padding inside the container */
  box-sizing: border-box;
  display: none;
  z-index: 10;
  opacity: 0;

  transform: translateY(100%);

  transition: transform 0.4s ease, opacity 0.7s ease; /* Smooth transition for transform */
}

.slider-container.show {
  transform: translateY(0);
  opacity: 1;
}

@media screen and (min-width: 768px) {
  .slider-container {
    display: block;
  }
}

.volume-slider {
  height: calc(100% - 30px);
  width: 3px; /* Make the slider thin */
  background-color: grey; /* Color of the slider */
  position: absolute;
  left: 50%; /* Center the slider within the container */
  transform: translateX(-50%);
  bottom: 10px; /* Offset by the bottom padding */
}

.volume-slider::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: var(--fill-percentage); /* Dynamic height based on volume */
  background-color: #f8d6fe; /* Color of the filled portion of the slider */
}

.volume-thumb {
  position: absolute;
  bottom: 0; /* Adjust based on initial volume */
  left: 50%; /* Center the thumb on the slider */
  transform: translateX(-50%);
  height: 15px;
  width: 15px;
  background-color: #f8d6fe;
  border-radius: 50%;
  cursor: pointer;
}

/* .volume-container:hover + .slider-container {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
} */

.volume-container,
.volume-slider,
.volume-thumb,
.slider-container {
  user-select: none;
}

/* Style for the play/pause button */
.footer button {
  padding: 1px 5px;
  background-color: inherit;
  color: white;
  border: none;
  cursor: pointer;
  /* font-size: 2em; */
}

.volume-button {
  font-size: 1.6em;
}

.playpause-button {
  font-size: 2.2em;
  padding: 0;
  margin: 0;
}

.spin-icon {
  animation: spin 1s linear infinite;
  font-size: 0.7em;
  padding: 10px 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes marquee {
  0% {
    transform: translateX(55%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.marquee-wrapper {
  flex-grow: 0;
  width: 250px; /* Adjust to the width you want */

  overflow: hidden; /* Prevent the marquee from going outside the wrapper */
  position: relative;
}

.marquee-wrapper::before,
.marquee-wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10px; /* adjust width to control the size of the blur */
  pointer-events: none;
  z-index: 2;
}

.marquee-wrapper::before {
  left: 0;
  background: linear-gradient(
    to right,
    black,
    transparent
  ); /* Change color as needed */
}

.marquee-wrapper::after {
  right: 0;
  background: linear-gradient(
    to left,
    black,
    transparent
  ); /* Change color as needed */
}

.marquee {
  white-space: nowrap;

  display: inline-block;
  animation: marquee 15s linear infinite;
  z-index: 1;
}

@media screen and (min-width: 1024px) {
  .playpause-button,
  .volume-button {
    /* Define the transition on the button elements */
    transition: color 0.2s ease-in-out;
  }

  .playpause-button:hover,
  .volume-button:hover {
    /* Define the hover color */
    color: rgb(157, 157, 157);
  }
}

@media (max-width: 769px) {
  .marquee-wrapper {
    width: 260px; /* Adjust to the width you want */
  }
  .footer {
    height: 6vh;
    width: 100%;
    padding: 15px 5px 10px 10px;
    border-top: 1px solid white;
    border-right: none;
    margin: 0;
  }
  /* 
  .playpause-button {
  margin-top: 3px;
  } */
}

@media (max-width: 380px) {
  .marquee-wrapper {
    width: 160px;
  }

  .footer {
    height: 40px;
    width: 100%;
    padding-top: 7px;
    padding-bottom: 0;
    border-top: 1px solid white;
    border-right: none;
  }

  .spin-icon {
    animation: spin 1s linear infinite;
    font-size: 0.6em;
    padding: 5px 5px;
  }
}
